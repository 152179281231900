<template>
  <LexmeaHeader />
  <div class="fixed top-12 z-10 h-min w-full overflow-hidden md:top-14">
    <LexmeaProgressBar :hidden="progressHidden" />
  </div>
  <main
    ref="mainContainer"
    class="content w-full overflow-auto"
    :class="{
      'overflow-x-hidden': !showLexmeaFooter,
    }"
  >
    <Transition
      enterFromClass="translate-x-[200%] opacity-0"
      leaveToClass="translate-x-[200%] opacity-0"
      enterActiveClass="transition duration-300"
      leaveActiveClass="transition duration-300"
    >
      <GoUpFAB v-if="showGoUpButton" />
    </Transition>
    <ClientOnly :load="FeedbackFAB" />
    <slot />
  </main>

  <ClientOnly :load="ImportLocalEntriesModal" />
  <ClientOnly v-if="cookieBannerTimeout" :load="LexmeaCookieBanner" />

  <LexmeaFooter v-if="showLexmeaFooter" />

  <ClientOnly :load="PwaHandler" />

  <VueQueryDevtools buttonPosition="bottom-left" />

  <ModalsContainer />
</template>

<script lang="ts" setup>
import "vue-final-modal/style.css";
import { useId } from "vue";
import { provideQueries } from "~/queries";
import { provideUseId } from "@headlessui/vue";
import { VueQueryDevtools } from "@tanstack/vue-query-devtools";
import { ModalsContainer } from "vue-final-modal";

const FeedbackFAB = () =>
  import("~/components/floatingButtons/FeedbackFAB.vue");

const ImportLocalEntriesModal = () =>
  import("~/components/modal/ImportLocalEntriesModal.vue");

const LexmeaCookieBanner = () => import("~/components/LexmeaCookieBanner.vue");

const PwaHandler = () => import("~/components/PwaHandler.vue");

provideUseId(() => useId()!.replace(/[-:]/, "_"));

provideQueries();
useAuth();
useSchemaStructuredData();
useMetadata();
useSeoHead();
usePosthog();

const pageContext = usePageContext();

watchEffect(() => {
  if (!import.meta.env.SSR) {
    window._paq?.push(["setCustomUrl", pageContext.urlPathname]);
    window._paq?.push(["trackPageView"]);
  }
});

const mainContainer = ref<HTMLElement | null>(null);
const { y } = useScroll(mainContainer);

const showGoUpButton = ref(false);

watch(
  () => pageContext.urlPathname,
  () => {
    y.value = 0;
  }
);

watch(
  () => y.value,
  () => {
    const viewportHeight = window.innerHeight;
    showGoUpButton.value = y.value > viewportHeight;
  }
);

const { isSmallerThanSm, isSmallerThanLg } = useLexMeaTailwindBreakpoints();
const { orientation } = useScreenOrientation();
const isSmallerThanDesktopAndOnLandscape = computed(
  () => isSmallerThanLg.value && orientation.value?.includes("landscape")
);
const showLexmeaFooter = computed(() => {
  return (
    //Don't show on mobile-landscape
    !isSmallerThanDesktopAndOnLandscape.value &&
    //show on library
    (pageContext.urlPathname.includes("/bibliothek/") ||
      //show on workspace but only if not on mobile
      (pageContext.urlPathname.includes("/gesetz/") && !isSmallerThanSm.value))
  );
});

// hack for vh
const { height } = useWindowSize();
watchEffect(() => {
  if (import.meta.env.SSR) return;
  // do it here because we can't inject dynamic properties to :root
  document.documentElement.style.setProperty(
    "--total-height",
    `${height.value}px`
  );
});

watchEffect(() => {
  if (import.meta.env.SSR) return;
  // do it here because we can't inject dynamic properties to :root
  document.documentElement.style.setProperty(
    "--footer-height",
    showLexmeaFooter.value ? "1rem" : "0px"
  );
});

const { transitionStartHook, transitionEndHook } = usePageTransition();

const progressHidden = ref(true);
transitionStartHook.on(() => (progressHidden.value = false));
transitionEndHook.on(() => (progressHidden.value = true));

onMounted(() => {
  // for persist toasts only
  setTimeout(() => {
    const toast = useToast();
    if (toast.toastInfo.value) {
      const { type, message } = JSON.parse(toast.toastInfo.value);
      toast[type](message, {
        onClose: () => {
          toast.toastInfo.value = null;
        },
      });
    }
  }, 0);
});

const cookieBannerTimeout = useTimeout(5000);
</script>

<style lang="postcss" global>
:root {
  --header-height: 3rem;

  @screen md {
    --header-height: 3.5rem;
  }

  --main-height: calc(
    var(--total-height) - var(--header-height) - var(--footer-height)
  );
}

.content {
  height: var(--main-height);
  min-height: var(--main-height);
  max-height: var(--main-height);
}

.v-popper--theme-tooltip {
  padding-right: 1rem !important;
  padding-left: 1rem !important;

  .v-popper__inner {
    @apply max-w-lg bg-lexmea-tooltip !important;

    /* increase the breakpoint to enlarge the tooltip width */
    @media (max-width: 576px) {
      max-width: 100% !important;
    }
  }
}

.v-popper--theme-tooltip .v-popper__arrow-outer {
  @apply border-lexmea-tooltip !important;
}

.v-popper--theme-tooltip .v-popper__arrow-inner {
  @apply border-lexmea-tooltip !important;
}
</style>
